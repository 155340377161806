<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ $t("label.lbl_stockInquiryDetails") }}</div>
      </v-col>
    </v-container>
    <v-container fluid>
      <v-row class="search-row">
        <!-- 取引先 -->
        <div class="search-autocomplete first-search-item">
          <v-text-field
            dense
            readonly
            v-model="suppliersSelectedName"
            :label="$t('label.lbl_supplier')"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          ></v-text-field>
        </div>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        :headers="headerItems"
        :items="inputList"
        :fixed-header="true"
        disable-sort
        disable-pagination
        :hide-default-footer="true"
        height="500px"
      >
        <!-- ヘッダーロット -->
        <template v-slot:[`header.lot`]="{ header }">
          <v-row>
            <v-col>
              <div>{{ header.text }}</div>
              <div>
                {{
                  $t("label.lbl_dueDate") +
                  "／" +
                  $t("label.lbl_lotSubkey1") +
                  "／" +
                  $t("label.lbl_lotSubkey2")
                }}
              </div>
            </v-col>
          </v-row>
        </template>
        <!-- 商品コード／品名改行&ツールチップ -->
        <template v-slot:[`item.productName`]="{ item }">
          <!-- 商品名が15文字以上だった場合ツールチップで表示 -->
          <div v-if="item.productName.length > 10">
            <div v-if="item.incidental">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="max-width: 400px" v-bind="attrs" v-on="on">
                    {{ item.productCnCd }}<br />
                    {{ item.incidental }}<br />
                    {{ item.productName }}
                  </div>
                </template>
                <div>{{ item.productCnCd }}</div>
                <div>{{ item.incidental }}</div>
                <div>{{ item.productName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="max-width: 400px" v-bind="attrs" v-on="on">
                    {{ item.productCnCd }}<br />
                    {{ item.productName }}
                  </div>
                </template>
                <div>{{ item.productCnCd }}</div>
                <div>{{ item.productName }}</div>
              </v-tooltip>
            </div>
          </div>
          <!-- 商品名が15文字以下だった場合ツールチップ無しで表示 -->
          <div v-else>
            <div>{{ item.productCnCd }}</div>
            <div>{{ item.incidental }}</div>
            <div>{{ item.productName }}</div>
          </div>
        </template>
        <!-- ロット/S/N-->
        <template v-slot:[`item.lot`]="{ item }">
          <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 350px" v-bind="attrs" v-on="on">
                  {{ item.lot }}<br />
                  {{ item.dueDate }}
                </div>
              </template>
              <span>{{ item.lot }}<br />{{ item.dueDate }}</span>
            </v-tooltip>
          </div>
          <div v-else>
            {{ item.lot }}<br />
            {{ item.dueDate }}
          </div>
        </template>
        <!-- * -->
      </v-data-table>
    </v-container>
    <!-- 閉じるボタン -->
    <v-card-actions>
      <div class="search-row-exeBtn">
        <div id="btn_close" align="right">
          <v-btn @click="close()" class="other-btn">
            <div id="lbl-screen-name">{{ $t("btn.btn_close") }}</div>
          </v-btn>
        </div>
      </div>
    </v-card-actions>
  </div>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import Loading from "@/components/loading";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";

export default {
  name: "stkInquiryDetails",
  components: {
    Loading,
  },
  props: {
    entity: { type: Object, default: null },
    detailDialogData: {},
    hoge1: {
      default: "",
    },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 取引先
    suppliersSelected: "",
    itemName: "",
    itemCd: "",
    qualityDiv: "",
    itemStandardSid: "",
    supplierList: [],
    suppliersSelectedName: "",
    stockList: [],
    headerItems: [
      // 品番
      {
        text: i18n.tc("label.lbl_productCnCd"),
        width: "20%",
        value: "productName",
        sortable: false,
        align: "left",
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "6%",
        sortable: false,
        align: "left",
      },
      // 倉庫
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "14%",
        align: "left",
        sortable: false,
      },
      // ロケーション
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "14%",
        align: "left",
        sortable: false,
      },
      //入庫日
      {
        text: i18n.tc("label.lbl_enteringDate"),
        value: "yyyymmdd",
        width: "6%",
        align: "right",
        sortable: false,
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "quantity",
        width: "7%",
        align: "right",
        sortable: false,
      },
    ],
    // 仮データ
    inputList: [],
    dummy_input: {
      productNm: "製品名",
      productCd: "a",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      ScheduleNo: "12345678901",
      expireDate: "2022/01/13",
    },
    defaultItem: {
      productNm: "",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      ScheduleNo: "12345678901",
      expireDate: "2022/01/13",
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    makeDummy() {
      this.inputList = [];
      this.inputList.push({
        productNm: "製品名",
        productCd: "製品コード",
        productAcc: "製品付帯",
        productQty: "0",
        caseSu: "0",
        ballSu: "0",
        baraSu: "0",
        lot: "ロット番号",
      });
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.suppliersSelected = this.entity.suppliersSelected;
      this.suppliersSelectedName = this.entity.suppliersSelectedName;
      this.qualityDiv = this.entity.qualityDiv;
      this.itemCd = this.entity.itemCd;
      this.itemName = this.entity.itemName;
      this.itemStandardSid = this.entity.itemStandardSid;

      // 詳細画面を開く
      this.getDetail();
    },

    // 在庫明細API
    getDetail() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 検索条件などのパラメータを設定する

      config.params.itemStandardSid = this.itemStandardSid;
      config.params.clientSid = this.suppliersSelected;

      config.params.qualityDiv = this.qualityDiv;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_STOCKDTL, config)
          .then((response) => {
            const list = [];
            const jsonData = JSON.parse(JSON.stringify(response.data));
            this.stockList = jsonData.resIdv.stocksDetail;

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              for (let i = 0; i < this.stockList.length; i++) {
                let lotNoObj = commonUtil.convertLotNo(this.stockList[i].lotNo);
                list.push({
                  incidental: this.itemCd.slice(-50).trim(),
                  productCnCd: this.itemCd.substring(0, 50).trim(),
                  productCnCdShow: this.itemCd,
                  productName: this.itemName,
                  status: this.stockList[i].qualityDivName,
                  warehouse: this.stockList[i].warehouseName,
                  location: this.stockList[i].locationNo,
                  lot: lotNoObj.lot, // ロット + S/N
                  dueDate: lotNoObj.dueDate, // 期限日 + 付属キー１ + 付属キー２
                  yyyymmdd: this.stockList[i].yyyymmdd,
                  quantity: commonUtil.formatToCurrency(Number(this.stockList[i].stocks)),
                });
              }
              this.inputList = list;
              resolve(response);
              // エラー時
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              // this.infoDialog.message = messsageUtil.getResMessage(jsonData.resCom);

              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("getDetail() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
  },
  watch: {
    entity: {
      handler(newValue, oldValue) {
        console.info("handler", newValue, oldValue);
      },
    },
    // eslint-disable-next-line no-unused-vars
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  padding: none;
  height: 3rem;
}

// .pad {
//   padding-left: 0;
//   padding-right: 0;
// }

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
